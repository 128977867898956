exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nominate-js": () => import("./../../../src/pages/nominate.js" /* webpackChunkName: "component---src-pages-nominate-js" */),
  "component---src-pages-nominees-js": () => import("./../../../src/pages/nominees.js" /* webpackChunkName: "component---src-pages-nominees-js" */),
  "component---src-pages-wdn-committee-js": () => import("./../../../src/pages/wdn-committee.js" /* webpackChunkName: "component---src-pages-wdn-committee-js" */),
  "component---src-templates-nominee-template-js": () => import("./../../../src/templates/nomineeTemplate.js" /* webpackChunkName: "component---src-templates-nominee-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

